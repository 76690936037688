import { FC, useEffect, useState } from 'react';
import { useQuery } from '../../hooks';
import { Battery, UserGuard, Warning } from '../../assets/svg';
import styles from './styles.module.css';
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { Button } from '../../components/Button';
import Navbar from '../../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ResultsImage from '../../assets/images/results-image.png';

export const Results: FC = () => {
    const { i18n } = useTranslation();
    const [resultsData, setResultsData] = useState<any>(undefined);
    const [errorData, setErrorData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [buttonText, setButtonText] = useState<string>('Show me the numbers!');
    const [isDetailedResults, setIsDetailedResults] = useState<boolean>(false);
    const query = useQuery();

    const displayDetailedResults = () => {
        setButtonText(!isDetailedResults ? t('results.navbarButton.energy') : t('results.navbarButton.measurements'));
        setIsDetailedResults(!isDetailedResults);
    };

    useEffect(() => {
        setButtonText(!isDetailedResults ? t('results.navbarButton.energy') : t('results.navbarButton.measurements'));
    }, [i18n.language, isDetailedResults]);

    useEffect(() => {
        if (resultsData || errorData) {
            return;
        }

        const results = query.get('results');
        const error = query.get('error');

        try {
            if (error || (error !== '' && error !== null)) {
                setErrorData(error);
                setLoading(false);

                return;
            }

            if (!results || results === '') {
                setErrorData(true);
                setLoading(false);

                return;
            }

            const resultsBuffer = Buffer.from(results, 'base64');
            const resultsObj = JSON.parse(resultsBuffer.toString());

            if (
                'MENTAL_SCORE' in resultsObj
                && 'PHYSICAL_SCORE' in resultsObj
                && 'PHYSIO_SCORE' in resultsObj
                && 'VITAL_SCORE' in resultsObj
                && typeof resultsObj.MENTAL_SCORE === 'number'
                && typeof resultsObj.PHYSICAL_SCORE === 'number'
                && typeof resultsObj.PHYSIO_SCORE === 'number'
                && typeof resultsObj.VITAL_SCORE === 'number'
            ) {
                setResultsData(resultsObj);
            } else {
                setErrorData(true);
            }
        } catch (e) {
            setErrorData(e);
        }

        setLoading(false);
    }, [errorData, query, resultsData]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    if (errorData) {
        return (
            <div style={{
                boxShadow: 'none',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#F0F3FF',
                gap: '1.5rem',
                padding: 0,
            }}>
                <Navbar isResults={false} />
                <div className={styles.container}>
                    <div className={styles.content}>
                        <Warning width='54px' />
                        <div className={styles.textContainer}>
                            <h1 className={styles.title}>{t('results.error.title')}</h1>
                            <p className={styles.description}>{t('results.error.description')}</p>
                        </div>
                        <Button text={t('results.error.button')} onClick={() => { window.location.href = '/'; }} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Navbar isResults={true} onClick={displayDetailedResults} buttonText={buttonText} />
            <div className={styles.results} style={{ marginTop: '1.5rem' }}>
                <div className={styles.top_container}>
                    <div className={styles.results_header}>
                        <h2 className={styles.title}>{isDetailedResults ? t('results.header.measurements.title') : t('results.header.energy.title')}</h2>
                        <div className={styles.paragraphs_container}>
                            <p className={`text-center ${styles.paragraph} ${styles.results_header_size}`}><Trans i18nKey={isDetailedResults ? t('results.header.measurements.description') : t('results.header.energy.description')} /></p>
                        </div>
                        {!isDetailedResults && <div className={`${styles.header_info} ${styles.results_header_size}`}>
                            <div className='d-flex'>
                                <UserGuard width='34px' />
                            </div>
                            <p className={`${styles.info_text} fs-italic`}>{t('results.header.energy.info')}</p>
                        </div>}
                    </div>
                    {isDetailedResults ?
                       <div className={styles.results_info} style={{ maxWidth: '600px', alignItems: 'flex-start', gap: '25px' }}>
                            <div className={styles.results_info_item} style={{ justifyContent: 'flex-start' }}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.measurements.title1')}</h3>
                                </div>
                            </div>
                            <table style={{ borderSpacing: 0 }}>
                                <tr>
                                    <th className={styles.table_title}>{t('results.measurementsTableHeader1.value')}</th>
                                    <th className={styles.table_title}>{t('results.measurementsTableHeader1.name')}</th>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.HR_BPM.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.HR_BPM')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.IHB_COUNT.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.IHB_COUNT')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.BR_BPM.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.BR_BPM_STRICT')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.BP_SYSTOLIC.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.BP_SYSTOLIC')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.BP_DIASTOLIC.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.BP_DIASTOLIC')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.HRV_SDNN.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.HRV_SDNN')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.BP_RPP.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.BP_RPP')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.BP_TAU.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.BP_TAU')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.AGE.toFixed(0)}</td>
                                    <td className={styles.table_item}>{t('results.measurements.AGE')}</td>
                                </tr>
                            </table>
                            <div className={styles.results_info_item} style={{ justifyContent: 'flex-start', marginTop: '35px' }}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.measurements.title2')}</h3>
                                </div>
                            </div>
                            <table style={{ borderSpacing: 0 }}>
                                <tr>
                                    <th className={styles.table_title}>{t('results.measurementsTableHeader2.value')}</th>
                                    <th className={styles.table_title}>{t('results.measurementsTableHeader2.name')}</th>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.HBA1C_RISK_PROB.toFixed(0)}%</td>
                                    <td className={styles.table_item}>{t('results.measurements.HBA1C_RISK_PROB')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.DBT_RISK_PROB.toFixed(0)}%</td>
                                    <td className={styles.table_item}>{t('results.measurements.DBT_RISK_PROB')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.HDLTC_RISK_PROB.toFixed(0)}%</td>
                                    <td className={styles.table_item}>{t('results.measurements.HDLTC_RISK_PROB')}</td>
                                </tr>
                                <tr>
                                    <td className={`${styles.table_item} text-center`}>{resultsData.HPT_RISK_PROB.toFixed(0)}%</td>
                                    <td className={styles.table_item}>{t('results.measurements.HPT_RISK_PROB')}</td>
                                </tr>
                            </table>
                        </div>
                        :
                        <div className={styles.results_info}>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.physical.title')}</h3>
                                    <p className={styles.paragraph_small}>{t('results.physical.description')}</p>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round(resultsData.PHYSICAL_SCORE)} width='5rem' />
                                </div>
                            </div>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.mental.title')}</h3>
                                    <p className={styles.paragraph_small}>{t('results.mental.description')}</p>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round(resultsData.MENTAL_SCORE)} width='5rem' />
                                </div>
                            </div>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.metabolic.title')}</h3>
                                    <p className={styles.paragraph_small}>{t('results.metabolic.description')}</p>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round((resultsData.PHYSIO_SCORE + resultsData.VITAL_SCORE) / 2)} width='5rem' />
                                </div>
                            </div>
                        </div>
                    }
                    {!isDetailedResults && <div className={styles.preFooter}>
                        <p className={styles.preFooterTitle}>{t('results.preFooter.title')}</p>
                        <div className={styles.preFooterContent}>
                            <p className={styles.preFooterDescription}><Trans i18nKey="results.preFooter.description" /></p>
                            <img src={ResultsImage} alt="Yoboo" className={styles.preFooterImage} />
                        </div>
                    </div>}
                </div>
                <div className={styles.results_footer}>
                    <div className={styles.results_footer_container}>
                        <div className={`${styles.paragraphs_container} ${styles.w_60}`}>
                            <h2 className={styles.bottom_title} style={{ color: '#FFFFFF' }}>{t('results.footer.title')}</h2>
                            <p className={styles.paragraph_small} style={{ color: '#FFFFFF' }}>{t('results.footer.description')}</p>
                        </div>
                        <Link to="/">
                            <Button className={styles.button_container} text={t('results.footer.button')} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
