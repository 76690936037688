import { Resource } from 'i18next';

const en: Resource = {
  translation: {
    footer: {
      cookiePreferences: 'Cookie preferences',
      allRightsReserved: '© 2024 Intake BV — All rights reserved',
      feelingGood: 'yoboo - Feeling good, driven by you',
    },
    welcome: {
      title: 'Curious about your <span class="gradient-yellow">energy level</span>?',
      headTitle: 'Your face reflects your energy!',
      description: "Your face reflects how you're feeling and your health status. Curious? With our short and simple scan, you'll gain more insight into three key energy sources: physical activity, rest, and nutrition.",
      startButton: 'Start the face scan',
      infoButton: 'Instructional video',
    },
    profile: {
      error: 'Please answer all questions to proceed',
      step0: {
        title: 'You are almost ready to start the scan',
        description: 'Fill out a few short additional questions so that your results accurately reflect your energy level. This will only take a few seconds of your time.',
        button: 'Proceed to questions',
      },
      step1: {
        progressText: 'Question 1 of 4',
        title: 'Some general questions...',
        button: 'Next questions',
        age: 'What is your age? *',
        man: 'Man',
        woman: 'Woman',
        gender: 'What was your gender at birth?',
        smoker: 'Do you smoke?',
        yes: 'Yes',
        no: 'No',
      },
      step2: {
        progressText: 'Question 2 of 4',
        title: 'Some questions about your physical characteristics...',
        button: 'Next questions',
        height: 'What is your height (in cm)? *',
        weight: 'What is your weight (in kg)? *',
      },
      step3: {
        progressText: 'Question 3 of 4',
        title: 'Some medical questions...',
        info: 'We are almost there!',
        button: 'Next questions',
        hypertension: 'Do you suffer from high blood pressure?',
        diabetic: 'Are you a diabetic?',
        medication: 'Do you take one or more medicines for high blood pressure?',
        yes: 'Yes',
        no: 'No',
      },
      step4: {
        progressText: 'Question 4 of 4',
        title: 'Your contact details...',
        info: 'so we can send you the results.',
        button: 'Start the face scan',
        email: 'E-mail address *',
        firstname: 'First name *',
        lastname: 'Last name *',
      },
      step5: {
        title: 'A few more checks...',
        info: 'After this the scan will start.',
        button: 'Start the face scan',
        list: {
          item0: 'Please check the following:',
          item1: '1. Select the correct camera',
          item2: '2. Give <strong>permission</strong> to your browser',
          item3: '3. Position your device <strong>10-15 cm</strong> in front of your face',
          item4: '4. Make sure your face fills the <strong>entire circle</strong>',
          item5: '5. Make sure the <strong>circle is well lit</strong> and clearly visible',
          item6: '6. <strong>Try not to move or talk</strong> once the scan starts. It lasts <strong>30 seconds</strong>',
        },
        cookieText: 'I agree to the',
        cookieLink: 'terms and conditions',
      },
      step6: {
        title: 'Terms and conditions',
        introduction: {
          p1: 'The special general terms and conditions of Yoboo-Mirror (hereinafter: "special general terms Yoboo") regulate the relationship between the user of the various Yoboo services (abbreviated as "Yoboo services") and Intake BV. By consulting or using Yoboo services in any way, the user accepts that the special general terms Yoboo are applicable.',
          p2: 'The special conditions, communicated to each user and accepted by them through the use of the Yoboo services, remain fully applicable, including any changes made in accordance with the section "validity and modification of the terms" of the special general terms. The most recent version is always available on the website (consultable at: <a href="https://www.yoboo.be/en/legal/terms-of-use" target="_blank">https://www.yoboo.be/en/legal/terms-of-use</a>). If the user no longer agrees with one or more provisions mentioned in the special general terms Yoboo, further consultation and/or use of the Yoboo services is no longer possible.'
        },
        identity: {
          title: 'Identity of Publisher and Manager Yoboo',
          p1: '<strong>Intake BV</strong>: Company with its registered office at B-2018 Antwerp, Quellinstraat 12 bus 6',
          p2: '<strong>VAT</strong>: BE0767-722-633 RPR Brussels',
          p3: '<strong>Customer Service</strong>: via contact form on the Yoboo website.<br />Or by mail at Quellinstraat 12 bus 6, 2018 Antwerp'
        },
        definitions: {
          title: 'Definitions',
          p1: '<strong>User</strong> hereinafter referred to as "the user": the user is an adult natural person who uses the Yoboo services. The user is only allowed to use the Yoboo services for the intended purposes.',
          p2: '<strong>Yoboo services</strong>: This includes the various services offered through the Yoboo app, the Yoboo portal, or the Yoboo website, or any other medium through which Intake BV makes its Yoboo services available now and in the future.',
        },
        purpose: {
          title: 'Purpose of Special General Terms and Conditions',
          p1: 'With these special general terms and conditions, as well as the general terms and conditions of all Yoboo services, Intake BV aims to provide the user with essential information about their rights and obligations when using the Yoboo services.',
          p2: 'User Responsibilities:',
          list: {
            item1: 'The use of the Yoboo services is not permitted for persons under the age of 18.',
            item2: 'The use of the Yoboo services is only possible if the user possesses an activation code.',
            item3: 'The access code to activate Yoboo services is personal and must not be shared with or used by another person.',
            item4: 'The user is responsible for maintaining the confidentiality and personal nature of their account information.',
            item5: 'The user is responsible for correctly entering the requested information.',
          },
        },
        general: {
          title: 'General Conditions Yoboo',
          p1: 'Registration: To access the various Yoboo services, the user must register via the Yoboo website.',
          p2: 'Any commercial use of the Yoboo services is strictly prohibited. Commercial use includes, but is not limited to, selling or renting the different functionalities of the Yoboo services, using the Yoboo services and its components solely for generating advertising or subscription revenue, organizing unfair competition, or engaging in acts contrary to fair trade practices towards Intake BV, or marketing or otherwise reusing the content, images, or any other material made available through the Yoboo services. This list is not exhaustive.',
          p3: 'The user remains solely responsible for the correct use of Yoboo services. The user will fully indemnify Intake BV for all damages and costs resulting from providing incorrect or incomplete information when managing Yoboo services or misuse/abuse of Yoboo services.',
          p4: 'By accepting the special additional terms of the various services, the data entered by the user on the Yoboo website will be used in the different services and vice versa.',
          p5: "All data obtained from the user through the use of the Yoboo-Mirror by Intake BV is not retained and is immediately deleted after use. The personal data of the user will be processed in accordance with Intake BV's privacy policy. When using the Yoboo-Mirror, only the name and email address are retained by Intake BV.",
          p6: 'The result of using the services is sent by email.',
        },
        website: {
          title: 'Website Use',
          p1: "The user is aware of the limitations and risks of using the internet or any other medium through which Intake BV makes its services available now and in the future. The user also acknowledges the risks of digitally or electronically storing and transmitting information. The user accepts that Intake BV is not liable for any damage resulting from the use of their websites or the internet due to the aforementioned risks. Intake BV cannot be held responsible for the content of websites or links provided on their website. The mere fact that these links are available on Intake BV's website does not imply any approval, acceptance, or acknowledgment of (the content of) those other websites. It is the user's own responsibility to check whether the websites are free of viruses and other programs or items that could be harmful to their equipment. The user must always consult and accept the applicable terms and/or disclaimers of these other websites/apps.",
          p2: 'The user is obliged to regularly check whether the most recent version of the Yoboo website is installed to ensure they have the most up-to-date information. Intake BV is in no way liable for decisions of any kind made by the user based on information obtained through the app.',
        },
        liability: {
          title: 'Liability of Intake BV',
          p1: 'The general terms, disclaimers, and other conditions remain fully applicable in addition to the general terms of the other Yoboo services.',
          p2: 'Although the Yoboo services exercise utmost care, it is possible that information may be incomplete, incorrect, not suitable for a specific situation, or not completely up-to-date.',
          p3: 'Except in cases of damage resulting from fraud, intent, or gross negligence by Intake BV, its employees, or agents, Intake BV and/or its directors, shareholders, agents, and representatives cannot be held liable for any direct, indirect, incidental, or consequential damages.',
          p4: 'The contractual and non-contractual liability of Intake BV and its directors, shareholders, agents, and representatives will always be limited to the amounts covered by the liability insurance taken out by Intake BV. This coverage amounts to a maximum of 20,000 euros per incident but is limited to 20,000 euros per year for all incidents combined.',
          p5: 'The above limitations of liability do not apply to the legal liability of Intake BV and its directors, shareholders, agents, and representatives in the event of death or bodily injury of the consumer as a result of their actions or omissions.',
          p6: 'Yoboo services cannot be qualified as services useful for medical purposes. The services do not constitute medical advice, diagnosis, or treatment and are not intended for medical use or insight. The services aim to provide the user with insight into their lifestyle and give inspirational guidelines for improving their lifestyle. The insights into health consist of physiological and psychological data but cannot be considered medical advice. The insights of Yoboo services are not intended for medical purposes or for diagnosing, treating, curing, or preventing any disease, condition, or injury. The insights do not replace the advice or diagnosis of a doctor or equivalent and/or a consultation with a doctor or other medical professional.',
          p7: 'The data obtained through the use of the Yoboo-Mirror is processed according to automated decision-making.',
        },
        access: {
          title: 'Access and Use of Yoboo Services',
          p1: 'It is strictly prohibited to attempt to gain access, in any way and by any technical means, to parts that are not publicly placed. It is also strictly prohibited to modify, adapt, add to, or remove items from the Yoboo services, in any way and by any technical means, except for the possibilities and spaces provided for this purpose.',
          p2: 'The user commits not to perform any act, regardless of the technical means used, that could at any time jeopardize the proper functioning of the Yoboo services in any way.',
        },
        validity: {
          title: 'Validity and Modification of the Terms',
          p1: 'If any provision of the special general terms Yoboo is found to be null, invalid, unenforceable, or illegal, the remaining provisions will remain unaffected. The gap in the special general terms Yoboo, resulting from the null, invalid, unenforceable, or illegal provision, will be filled in accordance with applicable law by applying a valid provision that most closely approximates the original economic intent of the terms.',
          p2: 'The fact that Yoboo fails to demand strict compliance with one or more of these provisions cannot be considered a tacit waiver of the rights they possess and does not prevent them from later demanding strict compliance.',
          p3: 'Intake BV reserves the right to make changes to these special general terms Yoboo at any time. Upon modification of the special general terms Yoboo, the user will always be notified and asked to accept them again if they wish to continue using the Yoboo services.',
          p4: '<strong>Intellectual Property Rights</strong>',
          p5: 'The concept, content, layout, structure, source codes, software, images, photos, recipes, animations, audiovisual works, texts, data, music, and all other components of the Yoboo services are and remain the exclusive property of Intake BV or the partners with whom Intake BV collaborates and are protected by various intellectual property rights (including copyright, trademark law, software law, design law, patent law, database law, sui generis right of the database producer, and/or other intellectual property rights or exclusivity rights), which is acknowledged and accepted by the user. By using the Yoboo services, the user does not acquire any rights.',
          p6: "The complete or partial reproduction, distribution, sale, publication, adaptation, and/or use, in any form and by any means, of the Yoboo website and its content is strictly prohibited. Storing any information and/or part of the app in an (electronic) database or otherwise is not allowed. By placing certain data, texts, images, or other files online, the user automatically grants Intake BV free permission to reproduce, communicate, or otherwise use the content of the post worldwide, both in the app and in any other advertising or commercial message. The user guarantees that they are authorized to give this permission and, for example, in the case of copyright-protected works, have the author's consent.",
        },
        questions: {
          title: 'Questions or Complaints:',
          p1: 'The user can initially address questions or complaints by using a contact form on the Yoboo website. If no reconciliation is reached, mediation will be initiated in mutual consultation. If this mediation does not bring a solution, the conflict will be submitted to the competent courts of Belgium and subject to Belgian law.',
          p2: 'If the customer does not reside in Belgium, they can also initially contact customer service. If the customer does not reach a solution for the conflict there, they can also contact the European Online Dispute Resolution Platform (<a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>) for online issues in their language.'
        },
        privacy: {
          title: 'Privacy Protection of Personal Data:',
          p1: "The provision of services as described in these general terms and conditions requires the collection and processing of the user's personal data. The protection of the user's personal data is extremely important to Intake BV. Intake BV makes it a point to protect these as well as possible and to always safeguard the user's rights when using these data in accordance with the applicable privacy and data protection regulations.",
          p2: "Intake BV processes the user's personal data for purposes and legal grounds described in the Privacy Statement. The Privacy Statement can be consulted online or requested via customer service. This Privacy Statement may be adjusted from time to time.",
          p3: 'The user undertakes to inform customer service promptly of any changes regarding their usual email address, identification, and address details to allow Intake BV to provide correct services.',
        },
        conflicts: {
          title: 'Conflicts:',
          p1: 'In case of a conflict between these general terms and the special additional terms of the specific Yoboo services, the special additional terms take precedence over the general terms.',
        }
      },
    },
    details: {
      title: "Let's get started!",
      step1: {
        title: 'Camera access',
        description: 'On the <strong>next screen</strong>, when you start the scan, you need to <strong>give\n permission to your browser</strong> to perform the measurements.',
        imageText: 'This looks something like this:',
      },
      step2: {
        title: 'Position your device',
        list: {
          item1: 'Hold your device <strong>10-15 cm in front of your face</strong>.',
          item2: 'Your face fills the entire circle.',
          item3: 'The circle is well lit and clearly visible.',
        }
      },
      step3: {
        title: 'Performing the scan',
        list: {
          item1: 'The <strong>scan starts automatically</strong> when the <strong class="green-text-details">green circles</strong> start rotating.',
          item2: 'Hold your <strong>face in the centre</strong>, close to the camera and keep it steady.',
          item3: 'Try <strong>not to move or talk</strong>.'
        }
      },
      button: 'Performing the scan!',
    },
    results: {
      results_done: {
        title: 'The scan is completed, great!',
        description: 'Your path to energy is just a click away! Via <strong>email</strong>, you will also receive your results and there, you can continue the journey.',
        button: 'Check your results',
      },
      navbarButton: {
        energy: 'Show me the numbers!',
        measurements: 'Back to energy report',
      },
      error: {
        title: 'Unable to start the scan',
        description: 'Your changes were saved, but we could not start the scan due to a technical issue on our end. Please try again.',
        button: 'Try again',
      },
      header: {
        energy: {
          title: 'Your energy score',
          description: 'Discover how you score on the <strong>energy sources</strong>: movement, rest, and nutrition. The fuller your battery, the more balanced your energy source is, and the more energetic you feel.',
          info: 'Your data is treated confidentially and not shared with others.',
        },
        measurements: {
          title: 'Insights based on the scan',
          description: 'An overview of the measurements and associated risks.',
        },
      },
      measurementsTableHeader1: {
        value: 'Value',
        name: 'Measurement',
      },
      measurementsTableHeader2: {
        value: 'Percentage',
        name: 'Risk',
      },
      measurements: {
        title1: 'Measurements',
        title2: "Risks",
        HR_BPM: 'Pulse rate (per minute)',
        IHB_COUNT: 'Irregular Heartbeat Count',
        BR_BPM_STRICT: 'Breathing Rate (per minute)',
        BP_SYSTOLIC: 'Systolic Blood Pressure (mmHg)',
        BP_DIASTOLIC: 'Diastolic Blood Pressure (mmHg)',
        HRV_SDNN: 'Heart Rate Variability (milliseconds)',
        BP_RPP: 'Cardiac Workload (Pluse rate x Systolic Blood Pressure)',
        BP_TAU: 'Vascular Capacity (seconds)',
        AGE: 'Facial Skin Age (year)',
        HBA1C_RISK_PROB: 'Hemoglobin A1C Risk',
        DBT_RISK_PROB: 'Type 2 Diabetes Risk',
        HDLTC_RISK_PROB: 'Hypertriglyceridemia Risk',
        HPT_RISK_PROB: 'Hypertension Risk',
      },
      physical: {
        title: 'Movement',
        description: 'The battery reflects the balance between movement, standing, and sitting in your daily life. The more balanced it is, the fuller the battery.',
        measurements: {
          body_mass_index: 'Body Mass Index (10 - 60)',
          facial_skin_age: 'Facial Skin Age (13 - 120)',
          waist_to_height_ratio: 'Waist to Height Ratio (25 - 70)',
          body_shape_index: 'Body Shape Index (6.19 - 8.83)',
          height: 'Height (cm)',
          weight: 'Weight (kg)',
          estimated_waist_circumference: 'Estimated Waist Circumference',
        },
      },
      mental: {
        title: 'Rest',
        description: 'How well do you manage stress and emotions? The better you are at this, the fuller the battery.',
        measurements: {
          mental_stress_index: 'Mental Stress Index (1 - 5,9)'
        },
      },
      metabolic: {
        title: 'Nutrition',
        description: 'Does your body efficiently convert food into energy? The more efficiently your body can do this, the fuller the battery.',
        measurements: {
          hypertension_risk: 'Hypertension Risk (0 - 100%)',
          type_2_diabetes_risk: 'Type 2 Diabetes Risk (0 - 100%)',
          hypercholesterolemia_risk: 'Hypercholesterolemia Risk (0 - 100%)',
          hypertriglyceridemia_risk: 'Hypertriglyceridemia Risk (0 - 100%)',
        },
      },
      preFooter: {
        title: 'Curious about how our Face Scan works?',
        description: 'You might be wondering how we can share all this information with you based on a face scan.<br /><br />Your <strong>face reflects your energy levels</strong>. With the right technology, <strong>underlying color changes</strong> in your face can be detected, which provide insights into the blood flow in your face. This is directly related to your heart, and through <strong>various algorithms</strong>, realistic values for your heart rate, breathing, and heart rate variability can be determined.<br /><br />These, along with other values, give an indication of your energy levels. Interesting, right?',
      },
      footer: {
        title: 'Would you like more insights?',
        description: 'Would you like more insights and guidance on energy and a healthy lifestyle in a fun and accessible way? Discover here how we can help you recharge your batteries.',
        button: 'Redo the scan',
      },
    },
    proceed: {
      slide1: {
        title: 'Your personal dashboard',
        description: 'You will receive an invitation in your mailbox to take the <strong>Willbeing® test</strong>, because at Yoboo, we believe that <strong>your motivation is the starting point</strong> for a more energetic and healthier lifestyle. Test completed? Great! You find the results on your <strong>personal dashboard</strong>.',
      },
      slide2: {
        title: 'One-on-one coaching',
        description: 'During a <strong>personal conversation</strong> with a yoboo coach, you will receive <strong>advice and insights</strong> about the yoboo pillars. Together, we look at what <strong>actions</strong> you can and want to integrate concerning <strong>nutrition, exercise and rest</strong>.',
      },
      slide3: {
        title: 'Ready, Set, Go',
        description: 'Get started now at your own pace. The yoboo coach provides you with an <strong>action card</strong> and our <strong>yoboo app</strong> inspires and motivates you in your personal journey.',
      },
    },
  },
};

export default en;
