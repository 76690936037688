import { ChangeEvent, FC, MouseEventHandler, useState } from 'react';
import Navbar from '../../components/Navbar';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Button } from '../../components/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useForm } from 'react-hook-form';
import crypto from 'crypto';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { publicKey } from './publikcKey';
import { useTranslation, Trans } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import styles from './styles.module.css';
import Input from '../../components/Input';
import { t } from 'i18next';
import { ArrowCarousel, Check } from '../../assets/svg';

type NPUser = {
  first_name: string;
  last_name: string;
  email: string;
  age: number;
  weight: number;
  height: number;
  gender: "male" | "female";
  smoking: boolean;
  bloodpressuremedication: boolean;
  diabetes: "0" | "type1" | "type2";
  event_name: string;
  gdpr_acceptance: boolean;
};

const Step0 = ({direction, handleNext}: { direction: string; handleNext: MouseEventHandler<HTMLButtonElement> }): JSX.Element => (
  <div className={`${styles.content} ${direction === 'back' && styles.slideInFromLeft}`}>
    <Check />
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{t('profile.step0.title')}</h1>
      <p className={styles.description}>{t('profile.step0.description')}</p>
    </div>
    <Button text={t('profile.step0.button')} onClick={handleNext} />
  </div>
);

const Step1 = ({
  direction,
  error,
  formData,
  control,
  handleChange,
  handleBack,
  handleNext
}: {
  direction: string;
  error: number;
  formData: any;
  control: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLButtonElement>;
}): JSX.Element => (
  <div className={`${styles.content} ${(direction === 'next' && styles.slideInFromRight) || (direction === 'back' && styles.slideInFromLeft)}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#2E3767" />
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step1.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '25%' }}></div>
        </div>
      </div>
    </div>
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{t('profile.step1.title')}</h1>
      <div className={styles.formContainer}>
        <Input
          required={true}
          id='age'
          type='number'
          label={t('profile.step1.age')}
          name="age"
          value={formData.age}
          onChange={handleChange}
          min={13}
          max={120}
        />
        <Controller
          name='gender'
          control={control}
          rules={{ required: true }}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <FormControl component='fieldset' required error={Boolean(error)} style={{ width: '100%', gap: '8px' }}>
              <FormLabel component='legend' style={{ fontFamily: 'Inter_Regular', fontSize: '16px', color: '#2E3767', marginBottom: '8px' }}>{t('profile.step1.gender')}</FormLabel>
              <RadioGroup aria-label='gender' name={name} value={formData.gender} onChange={handleChange}>
                <FormControlLabel id="genderMale" value='male' control={<Radio required />} label={t('profile.step1.man')} className={styles.radioContainer} />
                <FormControlLabel id="genderFemale" value='female' control={<Radio required />} label={t('profile.step1.woman')} className={styles.radioContainer} />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText> {/* Display the error message */}
            </FormControl>
          )}
        />
        <Controller
          name='smoker'
          control={control}
          rules={{ required: true }}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <FormControl component='fieldset' required error={Boolean(error)} style={{ width: '100%', gap: '8px' }}>
              <FormLabel component='legend' style={{ fontFamily: 'Inter_Regular', fontSize: '16px', color: '#2E3767', marginBottom: '8px' }}>{t('profile.step1.smoker')}</FormLabel>
              <RadioGroup aria-label='smoke' name={name} value={formData.smoker} onChange={handleChange}>
                <FormControlLabel id="smokerYes" value='yes' control={<Radio required />} label={t('profile.step1.yes')} className={styles.radioContainer} />
                <FormControlLabel id="smokerNo" value='no' control={<Radio required />} label={t('profile.step1.no')} className={styles.radioContainer} />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText> {/* Display the error message */}
            </FormControl>
          )}
        />
        {error === 1 && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
    </div>
    <Button text={t('profile.step1.button')} onClick={handleNext} />
  </div>
);

const Step2 = ({
  direction,
  error,
  formData,
  handleChange,
  handleBack,
  handleNext
}: {
  direction: string;
  error: number;
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLButtonElement>;
}): JSX.Element => (
  <div className={`${styles.content} ${(direction === 'next' && styles.slideInFromRight) || (direction === 'back' && styles.slideInFromLeft)}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#2E3767" />
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step2.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '50%' }}></div>
        </div>
      </div>
    </div>
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{t('profile.step2.title')}</h1>
      <div className={styles.formContainer}>
        <Input
          required={true}
          id='height'
          type='number'
          label={t('profile.step2.height')}
          name="height"
          value={formData.height}
          onChange={handleChange}
          min={120}
          max={220}
        />
        <Input
          required={true}
          id='weight'
          type='number'
          label={t('profile.step2.weight')}
          name="weight"
          value={formData.weight}
          onChange={handleChange}
          min={30}
          max={300}
        />
        {error === 2 && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
    </div>
    <Button text={t('profile.step2.button')} onClick={handleNext} />
  </div>
);

const Step3 = ({
  direction,
  error,
  control,
  formData,
  handleChange,
  handleBack,
  handleNext
}: {
  direction: string;
  error: number;
  formData: any;
  control: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLButtonElement>;
}): JSX.Element => (
  <div className={`${styles.content} ${(direction === 'next' && styles.slideInFromRight) || (direction === 'back' && styles.slideInFromLeft)}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#2E3767" />
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step3.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '75%' }}></div>
        </div>
      </div>
    </div>
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{t('profile.step3.title')}</h1>
      <p className={styles.info}>{t('profile.step3.info')}</p>
      <div className={styles.formContainer}>
        <Controller
          name='hypertension'
          control={control}
          rules={{ required: true }}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <FormControl component='fieldset' required error={Boolean(error)} style={{ width: '100%', gap: '8px' }}>
              <FormLabel component='legend' style={{ fontFamily: 'Inter_Regular', fontSize: '16px', color: '#2E3767', marginBottom: '8px' }}>{t('profile.step3.hypertension')}</FormLabel>
              <RadioGroup aria-label='hypertension' name={name} value={formData.hypertension} onChange={handleChange}>
                <FormControlLabel id="hypertensionYes" value='yes' control={<Radio required />} label={t('profile.step3.yes')} className={styles.radioContainer} />
                <FormControlLabel id="hypertensionNo" value='no' control={<Radio required />} label={t('profile.step3.no')} className={styles.radioContainer} />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText> {/* Display the error message */}
            </FormControl>
          )}
        />
        <Controller
          name='diabetic'
          control={control}
          rules={{ required: true }}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <FormControl component='fieldset' required error={Boolean(error)} style={{ width: '100%', gap: '8px' }}>
              <FormLabel component='legend' style={{ fontFamily: 'Inter_Regular', fontSize: '16px', color: '#2E3767', marginBottom: '8px' }}>{t('profile.step3.diabetic')}</FormLabel>
              <RadioGroup aria-label='diabetic' name={name} value={formData.diabetic} onChange={handleChange}>
                <FormControlLabel id="diabeticYes" value='yes' control={<Radio required />} label={t('profile.step3.yes')} className={styles.radioContainer} />
                <FormControlLabel id="diabeticNo" value='no' control={<Radio required />} label={t('profile.step3.no')} className={styles.radioContainer} />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText> {/* Display the error message */}
            </FormControl>
          )}
        />
        <Controller
          name='medication'
          control={control}
          rules={{ required: true }}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <FormControl component='fieldset' required error={Boolean(error)} style={{ width: '100%', gap: '8px' }}>
              <FormLabel component='legend' style={{ fontFamily: 'Inter_Regular', fontSize: '16px', color: '#2E3767', marginBottom: '8px' }}>{t('profile.step3.medication')}</FormLabel>
              <RadioGroup aria-label='medication' name={name} value={formData.medication} onChange={handleChange}>
                <FormControlLabel id="medicationYes" value='yes' control={<Radio required />} label={t('profile.step3.yes')} className={styles.radioContainer} />
                <FormControlLabel id="medicationNo" value='no' control={<Radio required />} label={t('profile.step3.no')} className={styles.radioContainer} />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText> {/* Display the error message */}
            </FormControl>
          )}
        />
        {error === 3 && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
    </div>
    <Button text={t('profile.step3.button')} onClick={handleNext} />
  </div>
);

const Step4 = ({
  direction,
  error,
  formData,
  handleChange,
  handleBack,
  handleNext
}: {
  direction: string;
  error: number;
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLButtonElement>;
}) => (
  <div className={`${styles.content} ${(direction === 'next' && styles.slideInFromRight) || (direction === 'back' && styles.slideInFromLeft)}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#2E3767" />
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step4.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '100%' }}></div>
        </div>
      </div>
    </div>
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{t('profile.step4.title')}</h1>
      <p className={styles.info}>{t('profile.step4.info')}</p>
      <div className={styles.formContainer}>
        <Input
          required={true}
          id='firstname'
          type='firstname'
          label={t('profile.step4.firstname')}
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
        />
        <Input
          required={true}
          id='lastname'
          type='lastname'
          label={t('profile.step4.lastname')}
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
        />
        <Input
          required={true}
          id='email'
          type='email'
          label={t('profile.step4.email')}
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {error === 4 && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
    </div>
    <Button text={t('profile.step4.button')} onClick={handleNext} />
  </div>
);

const Step5 = ({
  direction,
  error,
  formData,
  loading,
  handleTerms,
  handleBack,
  handleNext,
  onSubmit,
}: {
  direction: string;
  error: number;
  formData: any;
  loading: boolean;
  handleTerms: MouseEventHandler<HTMLDivElement>;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLSpanElement>;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
}) => (
  <div className={`${styles.content} ${(direction === 'next' && styles.slideInFromRight) || (direction === 'back' && styles.slideInFromLeft)}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#2E3767" />
      </div>
    </div>
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{t('profile.step5.title')}</h1>
      <p className={styles.info}>{t('profile.step5.info')}</p>
      <div className={styles.formContainer}>
        <ul className={styles.itemsContainer}>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item0" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item1" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item2" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item3" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item4" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item5" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item6" /></li>
        </ul>
        <div className={styles.checkboxContainer} onClick={handleTerms}>
          <input type="checkbox" id="terms" name="terms" className={styles.checkbox} required checked={formData.terms} />
          <p className={styles.checkboxLabel}>
            <span>{t('profile.step5.cookieText')}</span>
            &nbsp;
            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={(e) => {
              e.stopPropagation();
              handleNext(e);
            }}>{t('profile.step5.cookieLink')}</span>
          </p>
        </div>
        {error === 5 && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
    </div>
    <Button text={loading ? t('profile.step5.button') + '...' : t('profile.step5.button')} onClick={onSubmit} />
  </div>
);

const Step6 = ({
  direction,
  handleBack,
}: {
  direction: string;
  handleBack: MouseEventHandler<SVGSVGElement>;
}) => (
  <div className={`${styles.content} ${direction === 'next' && styles.slideInFromRight}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#2E3767" />
      </div>
    </div>
    <div className={styles.textContainer}>
      <div style={{ color: '#4f4f4f', textAlign: 'start' }}>
        <div style={{ color: '#4f4f4f', textAlign: 'start' }}>
          <div>Confidențialitatea dumneavoastră este o prioritate a Newpharma și a farmaciștilor săi&nbsp;(denumite &icirc;n continuare &bdquo;Newpharma&rdquo; sau &bdquo;noi&rdquo;). &Icirc;n acest sens, ne angajăm să respectăm datele cu caracter personal ale clienților noștri, non-clienți și utilizatorilor online (denumit &icirc;n continuare &bdquo;Dvs.&rdquo;), să le tratăm cu cea mai mare grijă și să asigurăm cel mai bun nivel de protecție &icirc;n conformitate cu Regulamentul 2016/679 din 27 aprilie 2016&nbsp;<em>privind protecția persoanelor cu privire la prelucrarea datelor cu caracter personal și libera circulație a acestor date</em>&nbsp;(denumit &icirc;n continuare &bdquo;GDPR&rdquo;) și legislația națională aplicabilă acestora.<br /><br />Această cartă de confidențialitate vă informează despre:
              <div id="content-30">
                  <ul>
                      <li>Ce date personale colectăm despre dvs. și motivele acestei colectări;</li>
                      <li>Cum utilizăm datele dumneavoastră cu caracter personal;</li>
                      <li>Drepturile dumneavoastră asupra datelor dumneavoastră personale și mijloacele de exercitare a acestora.</li>
                  </ul>
              </div>
          </div>
          <div>
              <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-31">1. Cine este responsabil pentru utilizarea datelor dumneavoastră &icirc;n contextul relației dumneavoastră cu serviciile noastre?</button>
              <div id="content-31">
                  <strong>Operatorii comuni ai prelucrării</strong>&nbsp;Operatorii comuni ai prelucrării datelor dumneavoastră cu caracter personal descriși &icirc;n prezenta Carta de confidențialitate sunt farmacia Newpharma SRL și farmaciștii acesteia, al căror sediu este stabilit la Rue Basse-Wez 315 &icirc;n 4020 Li&egrave;ge, Belgia - &icirc;nregistrată la BCE sub numarul 0838.666.156. &Icirc;n calitate de operator de date (vezi definiția glosarului), trebuie să respectăm o serie de obligații precum: garantarea drepturilor, sesizarea &icirc;ncălcărilor de securitate, &icirc;nființarea unui registru, desemnarea unui DPO etc. &Icirc;n calitate de operatori comuni, am alocat aceste obligații și le-am &icirc;nregistrat &icirc;ntr-un acord care definește &icirc;n mod transparent responsabilitățile și rolurile fiecăruia dintre noi &icirc;n &icirc;ndeplinirea sarcinilor noastre conform reglementărilor de salvgardare a datelor cu caracter personal. Această Carta de confidențialitate reflectă &bdquo;principalele linii&rdquo; ale acestui acord.<br />Orice &icirc;ntrebări sau solicitări cu privire la prelucrarea datelor dumneavoastră cu caracter personal pot fi trimise la următoarea adresă de e-mail:&nbsp;<a style={{ color: '#6dbf51' }}>privacy@support.newpharma.eu</a>&nbsp;. Vă puteți exercita drepturile față de și &icirc;mpotriva fiecăreia dintre părțile coresponsabile.
                  <p>&nbsp;</p>
                  <strong>Numirea unei persoane interne responsabile pentru utilizarea datelor dumneavoastră (&bdquo;DPO&rdquo;)</strong>&nbsp;Newpharma a desemnat un delegat pentru protecția datelor. &Icirc;n special, el este responsabil independent pentru asigurarea aplicării interne a regulilor de protecție și gestionare a datelor dumneavoastră și pentru colaborarea cu autoritățile de supraveghere. Iată datele de contact ale DPO-ului nostru: Newpharma &icirc;n atenția responsabilului cu protecția datelor<br />Rue Basse-Wez 315<br />4020 Li&egrave;ge<br />Belgia<br />E-mail:&nbsp;<a style={{ color: '#6dbf51' }}>privacy@support.newpharma.eu</a></div>
              </div>
              <div>
                  <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-32">2. De ce colectăm datele dumneavoastră cu caracter personal și pe ce bază?</button>
                  <div id="content-32">
                      <p>Colectăm date personale despre dvs. din diferite motive. Vă rugăm să rețineți că putem colecta și utiliza datele dumneavoastră cu caracter personal numai dacă această utilizare se bazează pe unul dintre temeiurile legale determinate de GDPR (de exemplu, respectarea unei obligații legale la care suntem supuși sau executarea unui contract &icirc;ncheiat cu dvs. ). Tabelul de mai jos enumeră exact scopurile pentru care Newpharma utilizează datele dumneavoastră cu caracter personal și temeiul legal corespunzător.</p>
                      <table style={{ borderCollapse: 'collapse', borderColor: '#000000', borderStyle: 'dotted', borderWidth: '1px' }}>
                          <tbody>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}><strong>Scopurile colectării datelor dumneavoastră cu caracter personal</strong></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}><strong>Temeiul legal pentru prelucrarea datelor dumneavoastră cu caracter personal</strong></td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Gestionarea relațiilor precontractuale cu potențialii clienți<br /><br /><em>Prelucrăm datele dumneavoastră cu caracter personal pentru a răspunde solicitărilor și/sau &icirc;ntrebărilor pe care ni le transmiteți (&icirc;n special prin intermediul formularului de contact de pe site-ul nostru)</em></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Avem un interes legitim &icirc;n prelucrarea datelor cu caracter personal ale potențialilor clienți, at&acirc;ta timp c&acirc;t acest lucru ne permite să răspundem solicitărilor și/sau &icirc;ntrebărilor acestora (articolul 6.1.f) din GDPR). Aceasta poate implica, de asemenea, operațiuni de prelucrare necesare pentru măsuri precontractuale (articolul 6.1.b) din GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Marketing față de clienții noștri și non-clienți, și anume:<br /><br />
                                      <ul>
                                          <li><em>trimiterea de scrisori de informare (buletine informative) și oferte promoționale;</em></li>
                                          <li><em>trimiterea de scrisori de informare (buletine informative), oferte promoționale și afișarea altor comunicări (&icirc;n special atunci c&acirc;nd navighezi pe site-ul nostru) personalizate pentru profilul tău;</em></li>
                                          <li><em>trimiterea cererilor de participare la sondaje de satisfacție</em></li>
                                          <li><em>afișarea reclamelor Newpharma pe site-uri și aplicații terțe, inclusiv rețele sociale și de publicitate</em></li>
                                      </ul>
                                  </td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Avem un interes legitim &icirc;n prelucrarea datelor cu caracter personal ale Clienților noștri (&icirc;n special cele obținute direct &icirc;n contextul v&acirc;nzării unui produs) pentru a-i informa despre produsele și promoțiile disponibile pe site care sunt susceptibile de a fi de interes să-i intereseze și să-i rețină/reangajeze, inclusiv prin site-uri sau aplicații terțe, precum și prin rețele sociale și de publicitate.<br /><br />&Icirc;n temeiul articolului XII.13(1) din Codul de drept economic și al Decretului regal din 4 aprilie 2003, prelucrăm, după obținerea consimțăm&acirc;ntului prealabil, datele cu caracter personal referitoare la datele de contact electronice ale non-clienților pentru a informa a produselor și promoțiilor disponibile pe site care sunt de natură să-i intereseze.<br /><br />Pentru a vă oferi cea mai relevantă experiență posibilă, Newpharma și farmaciștii săi vă pot personaliza e-mailurile, experiența dumneavoastră de navigare și alte comunicări sub rezerva obținerii consimțăm&acirc;ntului dumneavoastră explicit anterior (articolele 6.1.a) și 9.2.a) din GDPR). Pentru a face acest lucru, vor fi analizate comenzile și/sau interacțiunile dumneavoastră cu Newpharma și farmaciștii săi. Anumite informații despre sănătatea dumneavoastră pot fi dezvăluite cu această ocazie. Acestea vor fi folosite numai de Newpharma și de farmaciștii săi și &icirc;ntr-o manieră confidențială.</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Organizarea competițiilor<br /><br /><em>Datele participanților la o competiție sunt prelucrate de Newpharma cu scopul de a asigura buna desfășurare a Competiției, &icirc;n special de a contacta c&acirc;știgătorii și de a le oferi premiul eficient și rapid &icirc;n caz de c&acirc;știg.</em></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Executarea unui contract &icirc;ncheiat cu dumneavoastră (articolul 6.1.b) din GDPR), &icirc;n acest caz un contract de participare la un concurs.</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Trimiterea comunicatelor de presă</td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Avem un interes legitim &icirc;n prelucrarea datelor cu caracter personal ale jurnaliștilor &icirc;n scopul trimiterii și publicării comunicatelor de presă (articolul 6.1.f) din GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Gestionarea unui dosar de persoane de contact &icirc;n scopul campaniilor de marketing influencer<br /><br /><em>Creăm și operăm un fișier de adrese de bloggeri și influenceri &icirc;n scopul dezvoltării și &icirc;ntăririi imaginii noastre de brand, &icirc;mbunătățirii vizibilității noastre etc.</em></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Avem un interes legitim &icirc;n prelucrarea acestor date cu caracter personal &icirc;n scopul dezvoltării și consolidării imaginii noastre de marcă (articolul 6.1.f) din GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Gestionarea clienților<br /><br /><em>Prelucrăm date cu caracter personal pentru a efectua operațiuni legate de gestionarea clienților, &icirc;n special privind: &icirc;ncheierea și executarea contractelor; livrarea de medicamente; consiliere farmaceutică cu ocazia acestei livrări sau &icirc;n afara acesteia; sfaturi privind &icirc;ngrijirea, frumusețea, igiena și &icirc;n general &icirc;n domeniul sănătății (inclusiv sănătății animale); facturile ; contabilitate și &icirc;n special gestionarea conturilor clienților; lista persoanelor pentru care Ne rezervam dreptul de a refuza comanda sau cu care am incetat relatia comerciala, in conformitate cu conditiile noastre generale.</em></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Executarea unui contract &icirc;ncheiat cu dvs. (articolul 6.1.b) GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Gestionarea comenzii<br /><br /><em>Toate informațiile furnizate de Client, la codificarea datelor inerente comenzii sale, sunt &icirc;n special necesare pentru pregătirea comenzii &icirc;n centrul logistic Newpharma (sub supravegherea unui farmacist sau &bdquo;asistent de farmacie și cu respectarea bunei farmacii&rdquo;. practici), la plată și livrare.</em></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Executarea unui contract &icirc;ncheiat cu Dvs. (articolul 6.1.b), GDPR).<br /><br />Avem un interes legitim &icirc;n colectarea și utilizarea datelor dumneavoastră cu caracter personal &icirc;n contextul gestionării fraudei (articolul 6.1.f) din GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Gestionarea opiniilor oamenilor despre produse, servicii sau conținut</td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Avem un interes legitim &icirc;n prelucrarea datelor cu caracter personal referitoare la recenziile publicate pe site-ul nostru, at&acirc;ta timp c&acirc;t acestea ne permit să identificăm punctele forte și punctele slabe ale produselor, serviciilor și conținutului pe care le oferim (articolul 6.1.f) din GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Monitorizarea relației cu clienții<br /><br /><em>Prelucrăm datele dumneavoastră cu caracter personal &icirc;n special &icirc;n scopul realizării de sondaje de satisfacție, gestionării reclamațiilor dumneavoastră și ca parte a serviciului post-v&acirc;nzare.</em></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Executarea unui contract &icirc;ncheiat cu Dvs. (art. 6.1.b), GDPR).<br /><br />Avem un interes legitim (comercial) &icirc;n colectarea și utilizarea datelor dumneavoastră cu caracter personal pentru a măsura și garanta satisfacția clienților dumneavoastră (articolul 6.1.f) din GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Gestionarea litigiilor</td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Executarea unui contract &icirc;ncheiat cu Dvs. (articolul 6.1.b), GDPR).<br /><br />De asemenea, avem un interes legitim &icirc;n prelucrarea datelor cu caracter personal &icirc;n scopul apărării intereselor noastre, &icirc;n special, dar nu exclusiv, &icirc;n contextul litigiilor sau acțiunilor &icirc;n justiție (articolul 6.1.f) din GDPR).</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Statistici<br /><br /><em>Prin &bdquo;scopuri statistice&rdquo;, &icirc;nțelegem orice operațiune de colectare și prelucrare a datelor personale necesare cercetărilor statistice sau producerii de rezultate statistice. Aceste rezultate statistice pot fi utilizate &icirc;n continuare &icirc;n diverse scopuri, inclusiv &icirc;n scopul &icirc;mbunătățirii site-urilor web, produselor și serviciilor noastre. Scopurile statistice implică faptul că rezultatul prelucrării &icirc;n scopuri statistice nu permite nicio identificare a persoanelor ale căror informații au fost utilizate.</em></td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Avem un interes legitim &icirc;n prelucrarea datelor cu caracter personal ale Clienților noștri pentru a &icirc;mbunătăți site-urile web, produsele și serviciile oferite și pentru a avea o mai bună &icirc;nțelegere a publicului țintă (articolul 6.1.f) din GDPR)</td>
                              </tr>
                              <tr>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Achiziția de clienți noi vizați pe asemănarea acestora cu clienții existenți</td>
                                  <td style={{ border: '0.5px dotted rgb(0, 0, 0)' }}>Avem un interes legitim de a cere furnizorilor terți, inclusiv rețelelor sociale sau de publicitate, să caute printre clienții sau membrii lor potențiali vizați de asemănarea acestora cu Clienții noștri, pentru a le afișa reclame Newpharma sub rezerva respectării garanțiilor corespunzătoare.</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          <div style={{ color: '#4f4f4f', textAlign: 'start' }}>
              <div>
                  <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-43">3. Ce date personale colectăm despre dumneavoastră?</button>
                  <div id="content-43">
                      <p>Detaliem mai jos datele personale pe care le colectăm despre dumneavoastră, motivul colectării acestora precum și metodele de colectare a acestora (informații furnizate direct de dumneavoastră, colectate cu autorizația dumneavoastră sau colectate de sistemele noastre informatice).</p>
                      <table>
                          <tbody>
                              <tr>
                                  <td><strong>Scopurile colectării de date</strong></td>
                                  <td><strong>Datele personale colectate</strong></td>
                                  <td><strong>Metode de colectare</strong></td>
                              </tr>
                              <tr>
                                  <td><strong>Managementul relatiilor precontractuale cu potentialii clienti</strong></td>
                                  <td>
                                      <ul>
                                          <li>Date personale de identificare (nume, prenume, adresă de e-mail, companie, număr de telefon, număr de comandă, adresă IP etc.)</li>
                                          <li>C&acirc;mpuri libere și zone de comentarii</li>
                                          <li>Date de identificare electronică (adresă IP, cookie-uri, jurnal de conexiune, dispozitiv utilizat, browser utilizat și versiunea acestuia, parolă criptată etc.)</li>
                                          <li>Date demografice (v&acirc;rsta, sexul, data nașterii, țara dvs., limba dvs.)</li>
                                          <li>Date sensibile &icirc;n sensul articolului 9 din GDPR pentru care protecția juridică este &icirc;ntărită, și anume date de sănătate, numai dacă ți-ai indicat consimțăm&acirc;ntul pentru a primi conținut personalizat de la noi</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                              </tr>
                              <tr>
                                  <td><strong>Marketing pentru clienții noștri și non-clienți</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>Caracteristici demografice</li>
                                          <li>Date despre tranzacție (numărul tranzacției, detaliile achiziției produsului, marcajul de timp, codul promoțional utilizat etc.)</li>
                                          <li>Date despre comportamentul online (afișări de pagină și vizualizări ale produselor, plăți, evenimente de adăugare &icirc;n coș și evenimente de coș abandonat, interogări de căutare etc.)</li>
                                          <li>Date referitoare la tranzacție numai dacă v-ați indicat consimțăm&acirc;ntul pentru a primi conținut personalizat de la noi (numărul tranzacției, detaliile achiziției unui produs etc.)</li>
                                          <li>Date sensibile &icirc;n sensul articolului 9 din GDPR pentru care protecția juridică este &icirc;ntărită, și anume date de sănătate, numai dacă ți-ai indicat consimțăm&acirc;ntul pentru a primi conținut personalizat de la noi</li>
                                          <li>Date referitoare la contribuțiile persoanelor care postează opinii despre produse, servicii sau conținut.</li>
                                      </ul>
                                  </td>
                                  <td>Ca parte a acestui scop, datele sunt colectate direct de la Dvs. sau indirect prin intermediul unui terț căruia i-ați dat autorizația de a transmite datele dumneavoastră către Newpharma.</td>
                              </tr>
                              <tr>
                                  <td><strong>Organizarea concursurilor</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>C&acirc;mpuri libere și zone de comentarii</li>
                                          <li>Caracteristici demografice</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                              </tr>
                              <tr>
                                  <td><strong>Trimiterea comunicatelor de presă</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>C&acirc;mpuri libere și zone de comentarii</li>
                                          <li>Caracteristici demografice</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la dumneavoastră sau obținute prin intermediul site-ului companiei pentru care lucrați sau prin baze de date sau online.</td>
                              </tr>
                              <tr>
                                  <td><strong>Managementul clientilor</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>C&acirc;mpuri libere și zone de comentarii</li>
                                          <li>Caracteristici demografice</li>
                                          <li>Date referitoare la mijloacele de plată (extrasul de identitate bancar, inclusiv detaliile dvs. bancare etc.)</li>
                                          <li>Date despre tranzacții</li>
                                          <li>Date sensibile &icirc;n sensul articolului 9 din GDPR pentru care protecția juridică este &icirc;ntărită, și anume datele de sănătate</li>
                                          <li>Date referitoare la monitorizarea relatiei comerciale (cereri de proba, produs achizitionat, serviciu abonat, cantitate, suma, frecventa, adresa de livrare, istoricul achizitiilor si serviciilor prestate, returnarea produselor, corespondenta cu clientul si serviciul post-vanzare -vanzari). , schimburi și comentarii cu clienții, persoana(e) responsabilă(e) de relațiile cu clienții etc.)</li>
                                          <li>Date referitoare la plățile facturilor (termeni de plată, reduceri acordate, chitanțe, solduri și sume neachitate etc.)</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                              </tr>
                              <tr>
                                  <td><strong>Gestionarea comenzilor</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>C&acirc;mpuri libere și zone de comentarii</li>
                                          <li>Caracteristici demografice</li>
                                          <li>Date referitoare la metodele de plată</li>
                                          <li>Date despre tranzacții</li>
                                          <li>Date sensibile &icirc;n sensul articolului 9 din GDPR pentru care protecția juridică este &icirc;ntărită, și anume datele de sănătate</li>
                                          <li>Date referitoare la monitorizarea relatiei comerciale</li>
                                          <li>Date referitoare la plățile facturilor</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                              </tr>
                              <tr>
                                  <td><strong>Gestionarea opiniilor oamenilor despre produse, servicii sau conținut</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>Caracteristici demografice</li>
                                          <li>Date referitoare la contribuțiile persoanelor care postează opinii despre produse, servicii sau conținut.</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                              </tr>
                              <tr>
                                  <td><strong>Monitorizarea relatiei cu clientii</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>C&acirc;mpuri libere și zone de comentarii</li>
                                          <li>Caracteristici demografice</li>
                                          <li>Date referitoare la metodele de plată</li>
                                          <li>Date despre tranzacții</li>
                                          <li>Date sensibile &icirc;n sensul articolului 9 din GDPR pentru care protecția juridică este &icirc;ntărită, și anume datele de sănătate</li>
                                          <li>Date referitoare la monitorizarea relatiei comerciale</li>
                                          <li>Date referitoare la plățile facturilor</li>
                                          <li>Date referitoare la contribuțiile persoanelor care postează opinii despre produse, servicii sau conținut.</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                              </tr>
                              <tr>
                                  <td><strong>Gestionarea litigiilor</strong></td>
                                  <td>
                                      <ul>
                                          <li>Toate datele din diferite scopuri</li>
                                          <li>Date referitoare la infracțiuni, condamnări sau măsuri de securitate, &icirc;n special: fapte contestate; informatii, documente si piese colectate care tind sa stabileasca faptele susceptibile de a fi invocate; caracteristicile litigiului; data, natura, motivele, sumele și eventuala eșalonare a condamnărilor; comentarii referitoare la descrierea și monitorizarea procedurii</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                                  </tr>
                              <tr>
                                  <td><strong>Statistici</strong></td>
                                  <td>
                                      <ul>
                                          <li>Informații de identificare personală</li>
                                          <li>Date electronice de identificare</li>
                                          <li>C&acirc;mpuri libere și zone de comentarii</li>
                                          <li>Caracteristici demografice</li>
                                          <li>Date referitoare la metodele de plată</li>
                                          <li>Date despre tranzacții</li>
                                          <li>Date sensibile &icirc;n sensul articolului 9 din GDPR pentru care protecția juridică este &icirc;ntărită, și anume datele de sănătate</li>
                                          <li>Date referitoare la monitorizarea relatiei comerciale</li>
                                          <li>Date referitoare la plățile facturilor</li>
                                          <li>Date referitoare la contribuțiile persoanelor care postează opinii despre produse, servicii sau conținut.</li>
                                      </ul>
                                  </td>
                                  <td>&Icirc;n acest scop, datele sunt colectate direct de la Dvs.</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
              <div>
                  <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-44">4. Cui &icirc;mpărtășim datele dumneavoastră cu caracter personal?</button>
                  <div id="content-44">Ca parte a activităților noastre, este posibil să &icirc;mpărtășim datele dumneavoastră cu caracter personal. Desigur, facem &icirc;ntotdeauna acest lucru &icirc;n așa fel &icirc;nc&acirc;t să garantăm o protecție optimă a datelor dumneavoastră cu caracter personal.&nbsp;
                      <p>&nbsp;</p>
                      <strong>Partajarea datelor dumneavoastră cu caracter personal cu furnizorii de servicii, subcontractanții sau alte terțe părți</strong>
                      <p>&nbsp;</p>
                      Pentru a urmări scopurile de utilizare a datelor descrise la punctele 2 și 3, Newpharma &icirc;ncredințează anumite sarcini de prelucrare unor terți denumiți &bdquo;subcontractanți&rdquo;. Newpharma poate de asemenea, &icirc;n anumite cazuri, să comunice datele dumneavoastră furnizorilor de servicii și/sau partenerilor contractuali și comerciali care pot fi implicați &icirc;n prelucrarea datelor cu caracter personal menționate mai sus (punctele 2 și 3) și pot avea astfel acces la datele dumneavoastră cu caracter personal.
                      <p>&nbsp;</p>
                      <table>
                          <tbody>
                              <tr>
                                  <td><strong>Procesator/furnizor de servicii sau altă parte terță implicată &icirc;n partajarea datelor dumneavoastră cu caracter personal</strong></td>
                                  <td><strong>Locațiile subcontractantului</strong></td>
                              </tr>
                              <tr>
                                  <td>Furnizori de soluții pentru trimiterea de e-mailuri</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizori de soluții de asistență pentru clienți</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizori de soluții de gestionare a bazelor de date</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizori de soluții de plată online</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizorii de servicii de livrare</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Rețelele sociale și furnizorii de servicii de optimizare a strategiei de social media</td>
                                  <td>Statele Unite, Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizori de servicii de concurență online</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizorii de servicii de &icirc;ntreținere a infrastructurii și sistemelor, precum și &icirc;ntreținerea aplicațiilor</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizori de servicii de gazduire</td>
                                  <td>Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Furnizorii de servicii platforme de sondaj</td>
                                  <td>Statele Unite, Uniunea Europeană</td>
                              </tr>
                              <tr>
                                  <td>Rețele de publicitate</td>
                                  <td>SUA, Uniunea Europeană</td>
                              </tr>
                          </tbody>
                      </table>
                      <p>&nbsp;</p>
                      <strong>Cu autoritățile publice</strong>&nbsp;: ca răspuns la solicitările legale, inclusiv pentru a &icirc;ndeplini cerințele de securitate națională sau de aplicare a legii (cum ar fi NSSO, autoritățile fiscale etc.). &Icirc;n conformitate cu obligațiile noastre legale, &icirc;n special &icirc;n ceea ce privește prevenirea și detectarea infracțiunilor, putem transmite datele dumneavoastră: a) la cererea unei autorități judiciare sau de poliție ori a ofițerilor judiciari sau chiar a unei autorități administrative; sau b) cu bună-credință, consider&acirc;nd că o astfel de acțiune este necesară pentru a se conforma oricărei legi sau reglementări aplicabile; c) pentru a proteja și a apăra drepturile noastre sau ale altor utilizatori ai serviciilor noastre.
                      <p>&nbsp;</p>
                      <strong>&Icirc;n contextul unei tranzacții</strong>&nbsp;: cum ar fi o fuziune, achiziție, consolidare sau v&acirc;nzare de active, este posibil să &icirc;mpărtășim datele dumneavoastră cu caracter personal cumpărătorilor sau v&acirc;nzătorilor.</div>
                  </div>
              <div>
                  <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-45">5. Utilizăm datele dumneavoastră cu caracter personal?</button>
                  <div id="content-46">Cu autorizarea dumneavoastră prealabilă, folosim datele dumneavoastră &icirc;n beneficiul partenerilor noștri. &Icirc;n contextul dăruirii de cadouri (&icirc;n urma participării la concursuri, acțiuni de loialitate etc.) este posibil ca unele dintre datele dumneavoastră să le transmitem partenerilor noștri cu autorizarea dumneavoastră prealabilă.Comunicăm datele dumneavoastră partenerilor noștri de afaceri care vă vor contacta apoi. &Icirc;n acest caz, vă &icirc;ncurajăm să citiți propria lor Carta de protecție a datelor (de exemplu, atunci c&acirc;nd primiți e-mailuri pe care vi le trimit). Vă rugăm să rețineți că vă puteți retrage &icirc;n orice moment consimțăm&acirc;ntul acordat &icirc;n totalitate sau &icirc;n parte, urm&acirc;nd procedurile descrise la punctul 7.D.</div>
              </div>
          </div>
          <div style={{ color: '#4f4f4f', textAlign: 'start' }}>
              <div>
                  <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-56">6. C&acirc;t timp păstrăm datele dumneavoastră cu caracter personal?</button>
                  <div id="content-57">Newpharma a stabilit reguli precise cu privire la perioada de păstrare a datelor dumneavoastră cu caracter personal. Această durată variază &icirc;n funcție de diferitele obiective și trebuie să țină cont de eventualele obligații legale de păstrare a anumitor date. Mai jos veți găsi lista de scopuri, precum și perioadele de păstrare:&nbsp;
                      <p>&nbsp;</p>
                      <table>
                          <tbody>
                              <tr>
                                  <td><strong>Scopul prelucrarii</strong></td>
                                  <td><strong>Termen de valabilitate</strong></td>
                              </tr>
                              <tr>
                                  <td>Managementul relatiilor precontractuale cu potentialii clienti</td>
                                  <td>Perioada de păstrare este de 3 ani de la ultima acțiune/reacție din partea persoanei &icirc;n cauză.</td>
                              </tr>
                              <tr>
                                  <td>Marketing</td>
                                  <td>Perioada de păstrare este de 3 ani de la ultima acțiune/reacție din partea persoanei &icirc;n cauză.</td>
                              </tr>
                              <tr>
                                  <td>Organizarea concursurilor</td>
                                  <td>Perioada de valabilitate este de 10 ani de la &icirc;ncheierea competiției.</td>
                              </tr>
                              <tr>
                                  <td>Trimiterea comunicatelor de presă</td>
                                  <td>Perioada de reținere este de 1 an din momentul &icirc;n care ai cunoștință de &icirc;ncheierea activității tale profesionale.</td>
                              </tr>
                              <tr>
                                  <td>Managementul clientilor</td>
                                  <td>Perioada de valabilitate este de 10 ani de la ultima comandă plasată sau service efectuat</td>
                              </tr>
                              <tr>
                                  <td>Gestionarea comenzilor</td>
                                  <td>Termenul de valabilitate este de 10 ani de la plasarea comenzii</td>
                              </tr>
                              <tr>
                                  <td>Gestionarea opiniilor oamenilor despre produse, servicii sau conținut</td>
                                  <td>Perioada de păstrare a opiniilor despre produse, servicii sau conținut se extinde p&acirc;nă la &icirc;ncheierea comercializării produsului de către Newpharma și/sau de către producător sau distribuitor.</td>
                              </tr>
                              <tr>
                                  <td>Monitorizarea relatiei cu clientii</td>
                                  <td>Perioada de valabilitate este de 10 ani de la ultima comandă plasată</td>
                              </tr>
                              <tr>
                                  <td>Gestionarea litigiilor</td>
                                  <td>Perioada de valabilitate este de 10 ani de la ultima comandă plasată</td>
                              </tr>
                              <tr>
                                  <td>Statistici</td>
                                  <td>Perioada de păstrare este de la 3 la 10 ani, &icirc;n funcție de scopul inițial pentru care au fost colectate datele</td>
                              </tr>
                              <tr>
                                  <td>Achiziția de noi clienți a vizat asemănarea acestora cu clienții existenți.</td>
                                  <td>Datele sunt păstrate pentru timpul necesar procesului de creare a unor audiențe asemănătoare. Ele sunt apoi șterse.</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
              <div>
                  <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-57">7. Ce drepturi aveți asupra datelor dumneavoastră cu caracter personal și cum le puteți exercita?</button>
                  <div id="content-58">
                      <p>Dorim să vă informăm c&acirc;t mai clar cu privire la drepturile pe care le aveți asupra datelor dumneavoastră cu caracter personal. De asemenea, vrem să vă permitem să le exercitați &icirc;ntr-un mod simplu. Vă puteți exercita drepturile față de și &icirc;mpotriva Newpharma sau a farmaciștilor săi. Mai jos veți găsi un rezumat al drepturilor dumneavoastră cu o descriere a modului de exercitare a acestora.</p>
                      <p><strong>A. Dreptul de acces</strong></p>
                      <p>Puteți accesa toate următoarele informații referitoare la:</p>
                      <ul>
                          <li>Categoriile de date personale pe care le colectăm despre dvs.,</li>
                          <li>Motivele pentru care le folosim,</li>
                          <li>Categoriile de persoane cărora le-au fost sau vor fi comunicate datele dumneavoastră cu caracter personal și &icirc;n special persoanele care se află &icirc;n afara Europei,</li>
                          <li>Durata de păstrare a datelor dumneavoastră cu caracter personal &icirc;n sistemele noastre,</li>
                          <li>Dreptul dumneavoastră de a ne cere să corectăm, să vă ștergem datele personale sau să limitam utilizarea pe care o facem a datelor dumneavoastră cu caracter personal și dreptul de a vă opune acestei utilizări,</li>
                          <li>Dreptul dumneavoastră de a depune o pl&acirc;ngere la o Autoritate Europeană pentru Protecția Datelor,</li>
                          <li>Informații cu privire la sursa lor de unde nu am colectat datele dumneavoastră cu caracter personal direct de la dumneavoastră</li>
                          <li>Cum sunt protejate datele dumneavoastră cu caracter personal atunci c&acirc;nd sunt transferate &icirc;n țări din afara Europei.</li>
                      </ul>
                      <p><strong>Cum să vă exercitați dreptul de acces?</strong></p>
                      <p>Pentru a face acest lucru, pur și simplu trimiteți-ne o cerere prin&nbsp;<a style={{ color: '#6dbf51' }} href="https://www.newpharma.ro/contact">formularul nostru de contact</a>&nbsp;, av&acirc;nd grijă să specificați subiectul solicitării dvs. sau contactați-ne prin e-mail la&nbsp;<a style={{ color: '#6dbf51' }}>privacy@support.newpharma.eu</a> cu subiectul &bdquo;drept de acces: date personale&rdquo;, și trimiteți-ne ca atașament la e-mailul dvs. o copie față a cărții dvs. de identitate. Dacă nu vă indicați altfel, veți primi gratuit o copie a datelor dumneavoastră &icirc;n format electronic &icirc;n termen de 1 lună de la primirea solicitării. Acest termen va fi prelungit cu 2 luni &icirc;n cazul unei cereri care necesită o cercetare aprofundată.<br /><br />Dacă nu puteți accesa informațiile dumneavoastră prin e-mail, ne puteți trimite cererea dumneavoastră prin poștă la adresa indicată mai jos:<br /><br />Newpharma<br />Rue Basse-Wez 315<br />4020 Li&egrave;ge<br />Belgia<br /><br />&Icirc;n cazul unei cereri scrise, aceasta trebuie să fie semnată și &icirc;nsoțit de o copie a față a cărții de identitate. Cererea trebuie să specifice adresa la care trebuie trimis răspunsul. Apoi, un răspuns vă va fi trimis &icirc;n termen de 1 lună de la primirea cererii. Această perioadă va fi prelungită cu 2 luni &icirc;n cazul unei cereri care necesită o cercetare aprofundată sau &icirc;n cazul &icirc;n care primim prea multe solicitări.</p>
                      <p><strong>B. Dreptul de rectificare</strong></p>
                      <div>Ne puteți cere să corectăm și/sau să vă actualizăm datele personale.&nbsp;<strong>Cum?</strong></div>
                      <div>Pentru a face acest lucru, pur și simplu trimiteți-ne o solicitare prin&nbsp;<a style={{ color: '#6dbf51' }} href="https://www.newpharma.ro/contact">formularul nostru de contact</a> , av&acirc;nd grijă să specificați subiectul solicitării dvs. sau trimiteți-ne un e-mail la <a href="mailto:privacy@support.newpharma.eu">privacy@support.newpharma.eu</a> indic&acirc;ndu-vă&nbsp;numele dvs. de familie, prenumele și &icirc;n subiectul &bdquo;dreptul de a rectificare: date cu caracter personal&rdquo; precum și o copie de pe fața cărții dumneavoastră de identitate.<br /><br />De asemenea, nu uitați să indicați &icirc;n corpul e-mailului dumneavoastră motivul: rectificarea informațiilor inexacte, precum și informațiile care trebuie modificate, cu, dacă este cazul, dacă le aveți, dovada informațiilor corecte.<br /><br />De asemenea, vă puteți exercita acest drept trimiț&acirc;ndu-ne o scrisoare la următoarea adresă:<br /><br />Newpharma<br />Rue Basse-Wez 315<br />4020 Li&egrave;ge<br />Belgia<br /><br />Cererea dumneavoastră scrisă trebuie să fie semnată și &icirc;nsoțită de o copie a feței cărții dumneavoastră de identitate. Cererea trebuie să specifice adresa la care trebuie trimis răspunsul. Apoi, un răspuns vă va fi trimis &icirc;n termen de 1 lună de la primirea cererii. Această perioadă va fi prelungită cu 2 luni &icirc;n cazul unei cereri care necesită cercetări aprofundate sau &icirc;n cazul &icirc;n care Newpharma primește prea multe solicitări.</div>
                      <div>&nbsp;</div>
                      <div><strong>C. Dreptul la ștergere</strong></div>
                      <div>De asemenea, ne puteți contacta oric&acirc;nd pentru a ne cere să ștergem datele personale pe care le procesăm despre dumneavoastră, dacă vă aflați &icirc;ntr-una dintre următoarele situații:<br /><br />
                      <ul>
                          <li>Datele dumneavoastră cu caracter personal nu mai sunt necesare &icirc;n raport cu motivele pentru care au fost colectate sau prelucrate &icirc;n alt mod;</li>
                          <li>V-ați retras consimțăm&acirc;ntul pe care se bazează prelucrarea datelor dumneavoastră cu caracter personal efectuată de Newpharma;</li>
                          <li>Din motivul dumneavoastră, considerați că unul dintre tratamentele urmate vă &icirc;ncalcă intimitatea și vă provoacă daune excesive;</li>
                          <li>Nu mai doriți să primiți solicitări comerciale de la noi;</li>
                          <li>Datele dumneavoastră cu caracter personal nu sunt prelucrate &icirc;n conformitate cu GDPR și legea belgiană;</li>
                          <li>Datele dumneavoastră cu caracter personal trebuie șterse pentru a respecta o obligație legală care este prevăzută de legislația Uniunii Europene sau de legislația națională la care se supun Newpharma;</li>
                          <li>Datele dumneavoastră personale au fost colectate ca parte a ofertei unui site web destinat copiilor.</li>
                      </ul>
                      <p><strong>Cum?</strong>&nbsp;Pentru a face acest lucru, pur și simplu trimiteți-ne o cerere prin&nbsp;<a style={{ color: '#6dbf51' }} href="https://www.newpharma.ro/contact">formularul nostru de contact</a> , av&acirc;nd grijă să specificați subiectul solicitării dvs. sau trimiteți-ne un e-mail la <a href="mailto:privacy@support.newpharma.eu">privacy@support.newpharma.eu</a> indic&acirc;ndu-vă&nbsp;numele de familie, prenumele și &icirc;n subiectul &bdquo;dreptul de a ștergere: date cu caracter personal&rdquo; și să ne trimiteți ca atașament la adresa dvs. de e-mail o copie a feței cărții dvs. de identitate. De asemenea, nu uitați să indicați &icirc;n corpul e-mailului dvs. motivul pentru aceasta (de exemplu, ștergerea datelor dvs. atunci c&acirc;nd v-ați retras consimțăm&acirc;ntul (pe care se bazează prelucrarea).&nbsp;</p>
                      <p>De asemenea, vă puteți exercita acest drept trimiț&acirc;ndu-ne o scrisoare la următoarea adresă:</p>
                      <p>Newpharma<br />Rue Basse-Wez 315<br />4020 Li&egrave;ge<br />Belgia</p>
                      <p>Solicitarea dumneavoastră scrisă trebuie să fie semnată și &icirc;nsoțită de o fotocopie a feței cărții dumneavoastră de identitate.</p>
                      <p>Cererea trebuie să specifice adresa la care trebuie trimis răspunsul. Apoi, un răspuns vă va fi trimis &icirc;n termen de 1 lună de la primirea cererii. Această perioadă va fi prelungită cu 2 luni &icirc;n cazul unei cereri care necesită o cercetare aprofundată sau &icirc;n cazul &icirc;n care primim prea multe solicitări.</p>
                      <p>Cu toate acestea, este posibil să nu putem acorda cererea dumneavoastră pentru dreptul de a fi uitat. &Icirc;ntr-adevăr, trebuie reținut că acest drept nu este absolut. Trebuie să o echilibrăm cu alte drepturi sau valori importante, cum ar fi libertatea de exprimare, respectarea unei obligații legale la care suntem supuși sau motive importante de interes public.</p>
                      <p><strong>D. Dreptul de a obiecta</strong></p>
                      <div>
                          <ul>
                              <li>Vă puteți opune utilizării datelor dumneavoastră cu caracter personal &icirc;n scopuri de solicitare comercială și, &icirc;n special, acțiuni de publicitate.</li>
                              <li>Aveți dreptul de a vă opune prelucrării de către noi a datelor dumneavoastră cu caracter personal dacă, din propriul motiv, considerați că oricare dintre prelucrările efectuate vă &icirc;ncalcă confidențialitatea și vă provoacă daune excesive.</li>
                              <li>Vă puteți opune utilizării datelor dumneavoastră cu caracter personal &icirc;n scopuri de solicitare comercială și, &icirc;n special, acțiunilor de publicitate și achiziționării de noi clienți care vizează asemănarea acestora cu Clienții existenți.</li>
                          </ul>
                          <br />Nu ne puteți &icirc;mpiedica &icirc;n niciun caz să procesăm datele dumneavoastră:<br />
                          <ul>
                              <li>dacă prelucrarea este necesară pentru &icirc;ncheierea sau executarea contractului dumneavoastră.</li>
                              <li>dacă prelucrarea a fost impusă prin lege sau regulament. Acesta este mai ales cazul c&acirc;nd vă mutați &icirc;ntr-o altă municipalitate;</li>
                              <li>dacă prelucrarea este necesară pentru stabilirea, exercitarea sau apărarea drepturilor legale.</li>
                          </ul>
                          <p><strong>Cum?</strong>&nbsp;Pentru a face acest lucru, pur și simplu trimiteți-ne o cerere prin&nbsp;<a style={{ color: '#6dbf51' }} href="https://www.newpharma.ro/contact">formularul nostru de contact</a>&nbsp;, av&acirc;nd grijă să specificați subiectul solicitării dvs. sau trimiteți-ne un e-mail la&nbsp;<a style={{ color: '#6dbf51' }}>privacy@support.newpharma.eu</a>&nbsp;indic&acirc;nd &icirc;n subiect &bdquo;dreptul de opoziție: date personale&rdquo; și trimiteți trimiteți-ne o copie a față a cărții dvs. de identitate ca atașament la e-mailul dvs. Este important să indicați motivele cererii dumneavoastră de opoziție.&nbsp;</p>
                          <p>De asemenea, vă puteți exercita acest drept trimiț&acirc;ndu-ne o scrisoare la următoarea adresă:</p>
                          <p>Newpharma<br />Rue Basse-Wez 315<br />4020 Li&egrave;ge<br />Belgia</p>
                          <p>Solicitarea dumneavoastră scrisă trebuie să fie semnată și &icirc;nsoțită de o fotocopie a feței cărții dumneavoastră de identitate. Cererea trebuie să specifice adresa la care trebuie trimis răspunsul.</p>
                          <p>Apoi, un răspuns vă va fi trimis &icirc;n termen de 1 lună de la primirea cererii. Această perioadă va fi prelungită cu 2 luni &icirc;n cazul unei cereri care necesită o cercetare aprofundată sau &icirc;n cazul &icirc;n care primim prea multe solicitări.<br />Cu toate acestea, este posibil să putem răspunde solicitării dvs. Desigur, &icirc;n acest caz ne vom asigura că vă oferim cel mai clar răspuns posibil.</p>
                          <p><strong>E. Dreptul la portabilitate</strong></p>
                          <p>Acest drept vă oferă posibilitatea de a vă controla mai ușor și mai precis datele dumneavoastră personale:</p>
                          <ul>
                              <li>pentru a prelua datele dumneavoastră cu caracter personal care sunt prelucrate de noi, pentru uzul dumneavoastră personal și pentru a le stoca pe un dispozitiv sau un cloud privat, de exemplu.</li>
                              <li>pentru a transfera datele dumneavoastră personale de la noi către o altă companie, fie de către dumneavoastră, fie direct de către noi, cu condiția ca acest transfer direct să fie &bdquo;posibil din punct de vedere tehnic&rdquo;.</li>
                          </ul>
                          <p><br />Acest drept se aplică at&acirc;t datelor dvs. declarate &icirc;n mod activ, c&acirc;t și conștient, cum ar fi datele pe care le furnizați (de exemplu, datele personale de identificare) și informațiile pe care le colectăm.<br /><br />&Icirc;n schimb, datele personale care sunt derivate, calculate sau deduse din datele pe care le-ați furnizat sunt excluse de la dreptul la portabilitate deoarece sunt create de noi.</p>
                          <p><strong>Cum?</strong>&nbsp;Pentru a face acest lucru, pur și simplu trimiteți-ne o cerere prin&nbsp;<a style={{ color: '#6dbf51' }} href="https://www.newpharma.ro/contact">formularul nostru de contact</a> , av&acirc;nd grijă să specificați subiectul solicitării dvs. sau trimiteți-ne un e-mail la <a href="mailto:privacy@support.newpharma.eu">privacy@support.newpharma.eu</a> indiuc&acirc;ndu-vă&nbsp;numele de familie, prenumele și &icirc;n subiectul &bdquo;dreptul de a portabilitate: date cu caracter personal&rdquo; și să ne trimiteți ca atașament la adresa dvs. de e-mail o copie a față a cărții dvs. de identitate. Nu uitați să specificați &icirc;n e-mail fișierele &icirc;n cauză și tipul solicitării (returnarea datelor și/sau transferul către un nou furnizor de servicii).&nbsp;</p>
                          <p>De asemenea, vă puteți exercita acest drept trimiț&acirc;ndu-ne o scrisoare la următoarea adresă:</p>
                          <p>Newpharma<br />Rue Basse-Wez 315<br />4020 Li&egrave;ge<br />Belgia</p>
                          <p>Solicitarea dumneavoastră scrisă trebuie să fie semnată și &icirc;nsoțită de o fotocopie a feței cărții dumneavoastră de identitate. Cererea trebuie să specifice adresa la care trebuie trimis răspunsul. Apoi, un răspuns vă va fi trimis &icirc;n termen de 1 lună de la primirea cererii. Această perioadă va fi prelungită cu 2 luni &icirc;n cazul unei cereri care necesită o cercetare aprofundată sau &icirc;n cazul &icirc;n care primim prea multe solicitări.</p>
                          <p>Cu toate acestea, trebuie să știți că avem dreptul de a vă refuza cererea de portabilitate. &Icirc;ntr-adevăr, acest drept se aplică numai datelor cu caracter personal &icirc;n baza consimțăm&acirc;ntului dumneavoastră sau a executării unui contract &icirc;ncheiat cu dumneavoastră (pentru a afla cu exactitate ce date cu caracter personal pot face obiectul dreptului la portabilitate: a se vedea punctul 2 din prezenta Cartă &bdquo;cum colectăm datele dumneavoastră cu caracter personal și pe ce bază&rdquo;. De asemenea, acest drept nu trebuie să &icirc;ncalce drepturile și libertățile terților, ale căror date pot fi găsite &icirc;n datele transmise &icirc;n urma unei cereri de portabilitate.</p>
                          <p><strong>F. Dreptul la restricționarea prelucrării</strong></p>
                          <p>Aveți dreptul de a ne solicita limitarea datelor dumneavoastră, adică marcarea (de exemplu, o mutare temporară a datelor dumneavoastră către un alt sistem de prelucrare sau o blocare a datelor dumneavoastră care le face inaccesibile) a datelor dumneavoastră cu caracter personal &icirc;nregistrate, &icirc;n vederea la limitarea procesării lor viitoare.<br /><br />Vă puteți exercita acest drept atunci c&acirc;nd:</p>
                          <ul>
                              <li>acuratețea datelor &icirc;n cauză este contestată;</li>
                              <li>Datele dumneavoastră cu caracter personal nu sunt prelucrate &icirc;n conformitate cu GDPR și legea belgiană;</li>
                              <li>datele nu mai sunt necesare pentru atingerea scopurilor planificate inițial, dar nu pot fi &icirc;ncă șterse din motive legale (&icirc;n special pentru stabilirea, exercitarea sau apărarea drepturilor dumneavoastră legale);</li>
                              <li>decizia referitoare la opoziția dumneavoastră la prelucrare este &icirc;n curs.</li>
                          </ul>
                          <p><br />&Icirc;n cazul limitării prelucrării, datele dumneavoastră cu caracter personal nu vor mai face obiectul vreunei prelucrări fără consimțăm&acirc;ntul dumneavoastră prealabil, cu excepția conservării (stochării) a acestora.<br />Cu toate acestea, datele dumneavoastră cu caracter personal pot fi totuși prelucrate pentru stabilirea, exercitarea sau apărarea unor pretenții &icirc;n justiție, sau pentru protecția drepturilor unei alte persoane juridice sau fizice, ori din motive importante de interes public &icirc;n Uniunea Europeană sau &icirc;n statul membru.<br />&Icirc;n cazul &icirc;n care prelucrarea unora dintre datele dumneavoastră cu caracter personal este restricționată, vă vom ține la curent cu privire la momentul &icirc;n care măsura va fi ridicată.</p>
                          <p><strong>Cum?</strong>&nbsp;Pentru a face acest lucru, pur și simplu trimiteți-ne o cerere prin&nbsp;<a style={{ color: '#6dbf51' }} href="https://www.newpharma.ro/contact">formularul nostru de contact</a>&nbsp;, av&acirc;nd grijă să specificați subiectul solicitării dvs. sau trimiteți-ne un e-mail la&nbsp;<a style={{ color: '#6dbf51' }}>privacy@support.newpharma.eu</a> indic&acirc;nd numele, prenumele și &icirc;n subiectul &bdquo;dreptul la restricționare&rdquo;: date personale&rdquo;, precum și o copie a feței cărții dvs. de identitate.&nbsp;</p>
                          <p>De asemenea, nu uitați să includeți motivul și subiectul &icirc;n corpul e-mailului.</p>
                          <p>De asemenea, vă puteți exercita acest drept trimiț&acirc;ndu-ne o scrisoare la următoarea adresă:</p>
                          <p>Newpharma<br />Rue Basse-Wez 315<br />4020 Li&egrave;ge<br />Belgia</p>
                          <p>Cererea dumneavoastră scrisă trebuie să fie semnată și &icirc;nsoțită de o copie a feței cărții dumneavoastră de identitate. Cererea trebuie să specifice adresa la care trebuie trimis răspunsul. Apoi, un răspuns vă va fi trimis &icirc;n termen de 1 lună de la primirea cererii. Această perioadă va fi prelungită cu 2 luni &icirc;n cazul unei cereri care necesită cercetări aprofundate sau &icirc;n cazul &icirc;n care Newpharma primește prea multe solicitări.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div>
              <button style={{ color: '#4f4f4f', textAlign: 'left' }} aria-expanded="true" aria-controls="content-58">8. Datele dumneavoastră personale sunt transmise &icirc;n străinătate?</button>
              <div id="content-59">
                  <strong>Transfer de date &icirc;n Spațiul Economic European</strong><br />
                  <p>&Icirc;n scopul anumitor prelucrări, anumite date sunt transferate &icirc;n cadrul Spațiului Economic European (a se vedea punctul 4.1).</p>
                  <p>&Icirc;n cadrul Spațiului Economic European, vă rugăm să rețineți că datele cu caracter personal beneficiază de același nivel de protecție.</p>
                  <p><strong>Transfer de date &icirc;n afara Spațiului Economic European</strong></p>
                  <p>&Icirc;n scopul anumitor prelucrări, anumite date sunt transferate &icirc;n afara Spațiului Economic European (a se vedea punctul 4.1).<br /><br />Transferăm datele dumneavoastră cu caracter personal și/sau acordăm acces la acestea unui subcontractant, furnizor de servicii sau terț situat &icirc;n state nemembre ale Spațiului Economic European numai atunci c&acirc;nd:<strong><br /></strong></p>
                  <ul>
                      <li>este situat &icirc;ntr-un stat care asigură un nivel adecvat de protecție &icirc;n virtutea unei decizii de adecvare luate de Comisia Europeană;</li>
                      <li>au fost implementate măsuri de protecție adecvate &icirc;n conformitate cu GDPR, cum ar fi:
                          <ul>
                              <li>semnarea clauzelor contractuale tip adoptate de Comisia Europeană pentru transferul de date cu caracter personal către subcontractanții stabiliți &icirc;n țări terțe (2010/87/UE);</li>
                              <li>utilizarea regulilor corporative obligatorii aprobate sau;</li>
                              <li>aplicarea unui cod de conduită aprobat.</li>
                          </ul>
                      </li>
                  </ul>
                  <p><strong><br />Orice transfer de date cu caracter personal către o țară din afara SEE va &icirc;nceta imediat dacă decizia de adecvare sau altă garanție echivalentă pe care se bazează un astfel de transfer este invalidată sau condițiile nu mai sunt &icirc;ndeplinite.<br /></strong></p>
                  <p><strong><br /></strong>Pentru a obține mai multe informații și/sau o copie a garanțiilor luate, pur și simplu ne contactați prin&nbsp;<a style={{ color: '#6dbf51' }} href="https://www.newpharma.ro/contact">formularul nostru de contact</a>&nbsp;sau trimiteți-ne un e-mail la&nbsp;<a style={{ color: '#6dbf51' }}>privacy@support.newpharma.eu</a> indic&acirc;nd numele, prenumele și &icirc;n subiectul &bdquo;transferuri &icirc;n afara Uniunii Europene: date personale". Nu uitați să specificați și &icirc;n corpul e-mailului dumneavoastră informațiile exacte pe care doriți să le obțineți.</p>
              </div>
            </div>
          </div>
        </div>
        <h1 className={styles.titleCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1.5rem' }}>Termeni și condiții NewSKIN</h1>
        <p className={styles.smallTitleCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem', fontWeight: 'bold' }}>1. Acces la aplicația newSKIN</p>
        <p className={styles.descriptionCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem' }}>Pentru a accesa și utiliza acest instrument de analiză a frumuseții, trebuie să ai vârsta de 16 ani sau mai mare. Dacă ești minor, este necesară autorizarea prealabilă din partea părinților tăi. Poți accesa aplicația SELFIE gratuit, fără să te înregistrezi sau să creezi un cont.</p>

        <p className={styles.smallTitleCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem', fontWeight: 'bold' }}>2. Rutina de îngrijire cu NewSKIN by newpharma</p>
        <p className={styles.descriptionCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem' }}>Acest site oferă utilizatorilor o aplicație de realitate virtuală care le permite să selecteze rutina de îngrijire a pielii cea mai potrivită nevoilor lor de frumusețe. Utilizatorii pot folosi camera web a dispozitivului lor sau pot încărca o fotografie de pe dispozitivul lor. Rezultatul experienței este păstrat de Newpharma timp de 24 de ore și nu este păstrat de partenerii săi.</p>

        <p className={styles.smallTitleCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem', fontWeight: 'bold' }}>3. Analiza pielii bazată pe inteligența artificială</p>
        <p className={styles.descriptionCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem' }}>Acest site oferă utilizatorilor un serviciu de analiză a pielii. Acest serviciu online este capabil să detecteze și să analizeze semnele de îmbătrânire ale pielii dvs. datorită inteligenței artificiale. Fotografia dvs. va fi analizată și în funcție de vârsta și tipul de piele, veți descoperi matricea pielii și o rutină personalizată de frumusețe.</p>

        <p className={styles.smallTitleCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem', fontWeight: 'bold' }}>Fotografia nu este stocată și este ștearsă imediat după analiză.</p>

        <p className={styles.descriptionCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem' }}>Sfaturile date în cadrul experienței "Newpharma, expert personalizat" sunt o simulare concepută pentru a oferi sfaturi cosmetologice. Informațiile furnizate sunt strict indicative și nu pot în niciun fel să înlocuiască un diagnostic medical sau o consultație clinică, nici să înlocuiască un tratament medical. Prin urmare, nu putem garanta că veți fi complet mulțumit de sfaturile rezultate din utilizarea acestor instrumente și nu ne asumăm nicio responsabilitate pentru orice utilizare pe care o puteți face.</p>
        <p className={styles.descriptionCookie} style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgb(79, 79, 79)', fontSize: '1rem' }}>Pentru mai multe informații sau în caz de îndoială, vă recomandăm să consultați medicul dumneavoastră.</p>
      </div>
  </div>
);

export const Profile: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { i18n } = useTranslation();
  const { control } = useForm();
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState('');
  const [error, setError] = useState(0);

  const handleNext = () => {
    if (error === 7) return;

    setDirection('next');
    setStep(prev => {
      switch (prev) {
        case 1:
          if (formData.age === '' || formData.gender === '' || formData.smoker === '' || parseInt(formData.age) < 13 || parseInt(formData.age) > 120) {
            setError(prev);
            return prev;
          }

          break;

        case 2:
          if (formData.height === '' || formData.weight === '' || parseInt(formData.height) < 120 || parseInt(formData.height) > 220 || parseInt(formData.weight) < 30 || parseInt(formData.weight) > 300) {
            setError(prev);
            return prev;
          }

          break;

        case 3:
          if (formData.hypertension === '' || formData.diabetic === '' || formData.medication === '') {
            setError(prev);
            return prev;
          }

          break;

        case 4:
          if (formData.email === '' || formData.firstname === '' || formData.lastname === '' || !formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setError(prev);
            return prev;
          }

          break;
      }

      setError(0);
      return prev + 1;
    });
  }

  const handleBack = () => {
    setDirection('back');
    setStep(prev => prev - 1);
  }

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    age: "",
    diabetic: "",
    height: "",
    hypertension: "",
    medication: "",
    gender: "",
    smoker: "",
    weight: "",
    email: "",
    firstname: "",
    lastname: "",
    terms: false,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      let isError = 0;

      if (name === 'age' && value > 120) isError = 7;
      if (name === 'age' && value < 13) isError = 7;

      if (name === 'height' && value > 220) isError = 7;
      if (name === 'height' && value < 120) isError = 7;

      if (name === 'weight' && value > 300) isError = 7;
      if (name === 'weight' && value < 30) isError = 7;

      if (name === 'firstname' && !value.match(/^[a-zA-Z\s-]*$/)) return { ...prevFormData };
      if (name === 'lastname' && !value.match(/^[a-zA-Z\s-]*$/)) return { ...prevFormData };

      setError(isError);
      return { ...prevFormData, [name]: value };
    });
  };

  const handleTerms = () => {
    setFormData((prevFormData) => {
      if (!prevFormData.terms) {
        setError(0);
      }

      return { ...prevFormData, terms: !prevFormData.terms };
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (!formData.terms) {
      setError(5);
      return;
    }
  
    setLoading(true);
    const { age, diabetic, height, hypertension, medication, gender, smoker, weight, email, firstname, lastname } = formData;
    const sessionId = uuidv4(); // Unique identifier for the session, passed back as part of the result object to identify the measurement

    registerUser({
      first_name: firstname,
      last_name: lastname,
      email: email,
      age: parseInt(age),
      weight: parseInt(weight),
      height: parseInt(height),
      gender: gender === 'male' ? 'male' : 'female',
      smoking: smoker === 'yes' ? true : false,
      bloodpressuremedication: medication === 'yes' ? true : false,
      diabetes: diabetic === 'yes' ? 'type1' : '0',
      event_name: localStorage.getItem('event_name') || '',
      gdpr_acceptance: formData.terms,
    }, sessionId);

    // Get the token ID
    const { data: configResponseData } = await axios.get('/api/configId');

    // Get a token from the back end
    const { data: tokenResponseData } = await axios.post('/api/token');
    const { Token, RefreshToken } = tokenResponseData;

    const payload = {
      identifier: uuidv4(), // Unique identifier for the user, it will be used to tag the measurement
      age: parseInt(age),
      height: parseInt(height),
      weight: parseInt(weight),
      gender,
      smoking: smoker === 'yes' ? '1' : '0',
      antihypertensive: hypertension === 'yes' ? '1' : '1',
      bloodpressuremedication: medication === 'yes' ? '1' : '0',
      diabetes: diabetic === 'yes' ? 'type1' : '0',
    };

    const buffer = Buffer.from(JSON.stringify(payload));
    const encryptedProfile = crypto.publicEncrypt(publicKey, buffer);

    const sessionIdValue = !!sessionId ? sessionId : 'undefined';

    //original url: awe.na-east.nuralogix.ai
    window.location.href = `https://scan.newpharma.health/c/${
      configResponseData.configId
    }/${encodeURIComponent(encryptedProfile.toString('base64'))}/${encodeURIComponent(
      Token,
    )}/${encodeURIComponent(RefreshToken)}/${sessionIdValue}`;
  };

  const registerUser = (user: NPUser, sessionId: string) => {
    axios.post('/api/register', { user, sessionId });
  };

  return (
    <Paper
        style={{
          boxShadow: 'none',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F0F3FF',
          gap: '1.5rem',
          padding: 0,
        }}
    >
      <Navbar isResults={false} />
      <div className={styles.container}>
        {step === 0 && <Step0 direction={direction} handleNext={handleNext} />}
        {step === 1 && <Step1 direction={direction} error={error} formData={formData} control={control} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} />}
        {step === 2 && <Step2 direction={direction} error={error} formData={formData} handleChange={handleChange} handleBack={handleBack} handleNext={handleNext} />}
        {step === 3 && <Step3 direction={direction} error={error} formData={formData} control={control} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} />}
        {step === 4 && <Step4 direction={direction} error={error} formData={formData} handleChange={handleChange} handleBack={handleBack} handleNext={handleNext} />}
        {step === 5 && <Step5 loading={loading} direction={direction} error={error} formData={formData} handleTerms={handleTerms} handleBack={handleBack} handleNext={handleNext} onSubmit={onSubmit} />}
        {step === 6 && <Step6 direction={direction} handleBack={handleBack} />}
      </div>
    </Paper>
  );
};
